.inputContainer {
  display: flex;
  flex-direction: column;
  gap: 7px;

  padding-top: 20px;
  width: 100%;
}
.label {
  color: #fff;
  font-size: 19px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 125%;

  cursor: pointer;

  position: relative;
  display: flex;
  gap: 6px;
}
.required {
  color: #ff5151;
}
.inputWrapper {
  border-radius: 5px;
  border: 1px solid #39393c;
  background: #2a2a2a;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 12px;
}
.input {
  color: #4cccff;
  font-size: 15px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 12px 12px;
  outline: none;
  border: none;
  width: 100%;
  background: #2a2a2a;
}
.text {
  color: #4cccff;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 19.8px;
} /* Chrome, Safari, Edge, Opera */
.input::-webkit-outer-spin-button,
.input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.input[type="number"] {
  -moz-appearance: textfield;
}
.currency {
  color: #4cccff;
  font-size: 12px;
  font-family: Fira Code;
  font-style: normal;
  font-weight: 400;
  line-height: 14.4px;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}
@media only screen and (max-width: 520px) {
  .input {
    padding: 10px 12px;
    font-size: 14px;
  }
}
